<template>
	<div class="card card-custom card-stretch gutter-b" style="min-height: 68em">
		<div class="card-header border-0 pt-3 d-flex align-items-center" style="min-height: 40px;">
			<div>
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label font-weight-bolder">
						Sites
					</span>
				</h3>
			</div>
			<div class="card-toolbar">
				<div class="search-box">
					<b-form-input class="search-bar" size="sm" v-model="searchText" placeholder="Search" debounce="100" />
					<b-icon-search class="search-icon" />
				</div>
				<router-link :to="{ name: 'adminnewsite' }">
					<button class="btn btn-light-primary font-weight-bolder text-uppercase px-5 py-2 ml-5">Create New Site</button>
				</router-link>
			</div>
		</div>
		<div class="card-body pt-2 pb-0" style="height: 200px;">
			<ITCSpinner :loaded="loaded">
				<!--begin::Table-->
				<b-table :items="filteredSites" :fields="fields" :current-page="currentPage" :per-page="perPage" show-empty thead-class="hidden-header">
					<template #cell(SiteId)="data">
						<div style="min-width:93px;">
							<router-link :to="{ name: 'admineditsite', params: { id: data.item.SiteId } }" :key="data.item.SiteId">
								<div class="fa fa-edit"></div>
							</router-link>
							{{ data.value }}
							<div
								v-if="data.item.count < 1"
								v-b-modal="'myModal' + data.item.SiteId"
								class="fa fa-times-circle"
								style="color:red; cursor: pointer; margin-left:5px;"
							></div>
							<b-modal
								:id="'myModal' + data.item.SiteId"
								title="Delete Site"
								ok-title="Delete"
								ok-variant="danger"
								@ok="deleteSite(data.item.SiteId)"
							>
								<p class="my-4">Are you sure you want to delete site {{ data.item.Name }}?</p>
							</b-modal>
						</div>
					</template>

					<template #cell(Name)="data">
						<router-link :to="{ name: 'site', params: { id: data.item.SiteId } }">
							{{ data.value }}
						</router-link>
					</template>

					<template #cell(icon)="data">
						<img width="20" :src="'/media/img/ICON-' + (data.item.icon ? data.item.icon : 'dish') + '.png'" />
					</template>
				</b-table>
			</ITCSpinner>
		</div>
		<div class="footer">
			<span>{{ filteredSites.length }} sites</span>
			<span @click.prevent="pageChange">
				<b-pagination class="pagination" v-model="currentPage" :total-rows="filteredSites.length" :per-page="perPage" />
			</span>
		</div>
	</div>
</template>

<script>
import swMixin from '@/core/services/mixins/serviceworker.mixin';
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';

export default {
	name: 'AdminSiteList',
	mixins: [swMixin],
	data() {
		return {
			loaded: false,
			sitesCount: 0,
			sites: [],
			searchText: '',
			currentPage: 1,
			perPage: 18,
			predicate: 'Name',
			reverse: true,
			fields: [
				{ key: 'SiteId', class: 'align-middle' },
				{ key: 'Name', class: 'align-middle text-wrap' },
				{ key: 'icon', class: 'align-middle text-wrap' },
			],
		};
	},
	components: {
		ITCSpinner,
	},
	computed: {
		filteredSites() {
			if (!this.searchText) return this.sites;
			return this.sites.filter(site => {
				return site.Name.toLowerCase().includes(this.searchText.toLowerCase()) || site.SiteId.toString().includes(this.searchText.toString());
			});
		},
	},
	methods: {
		getSites() {
			this.loaded = false;
			this.$http
				.get('allsites?admin=1')
				.then(res => {
					this.sites = res.data.data;
					this.sitesCount = res.data.data.length;
					this.loaded = true;
				})
				.catch(err => {
					console.log('Error (allsites?admin=1)', err);
				});
		},
		pageChange() {
			this.getSites();
		},
		deleteSite(id) {
			this.$http
				.post('/deletesite', { id: id })
				.then(res => {
					if (res.status === 200) {
						let index = this.sites.findIndex(site => site.SiteId === id);
						this.sites.splice(index, 1);
					}
				})
				.catch(err => {
					console.log('Delete Site Error:', err);
				});
		},
	},
	mounted() {
		this.getSites();
		this.searchText = this.$route.query.siteq || '';
	},
	watch: {
		searchText(newVal) {
			if (newVal !== this.$route.query.siteq) {
				this.$router.replace({ query: { ...this.$route.query, siteq: newVal || undefined } }).catch(err => {});
			}
		},
	},
};
</script>

<style scoped>
.hidden-header {
	display: none;
}
.search-box {
	position: relative;
}
.search-bar {
	padding-left: 30px;
}
.search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}
.footer {
	padding: 15px 30px 0px;
}
.pagination {
	float: right;
}
</style>
